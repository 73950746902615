.back-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 99999;
    background: #e43c5c;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    transition: all 0.4s;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .back-to-top i {
    font-size: 24px;
    color: #fff;
  }
  
  .back-to-top:hover {
    background: #fe2048;
    color: #fff;
  }
  
  .back-to-top.active {
    visibility: visible;
    opacity: 1;
  }
  