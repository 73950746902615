body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* Donate Container */
  .donate-container {
    width: 100%;
    margin: 0 auto;
    max-width: 1200px;
  }
  
  .donation-banner-image {
  background-size: cover;
    background-position: center;
    height: 450px;
    width: 100%;
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    border: none;
  }
  
  /* Donate Message Section */
  .donate-message-section {
    padding: 20px;
    background-color: #f8f8f8;
  }
  
  .donate-message-section h2 {
    font-size: 2rem;
    color: #2786c1;
    margin-bottom: 20px;
  }
  
  .donate-message-section p {
    font-size: 14px;
    line-height: 1.428;
    color: #333;
    margin-bottom: 10px;
  }
  
  /* Form Section */
  .donation-form-section {
    padding: 40px;
    text-align: center;
    background-color: #fff;
    display: flex;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    flex-wrap: wrap; 
  }
  .dfs h3 {
    font-size: 1.6rem;
    color: #2786c1;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .donation-scanner {
    width: 100%;
    max-width: 350px;
    object-fit: cover;
  }
  
  .donation-scanner{
    flex: 1;
    /* margin-right: 20px; */
    /* margin-bottom: 400px; */
    display: flex;
    flex-direction: column; 
    gap: 1rem; /* Space between images */
  }
  
  .donation-details{
    flex: 2;
    margin-top: -15.1%;
  }
  .donation-details h5{
    margin-left: -22.5%;
  }
  .donation-details p {
    font-size: 14px;
    color: #262424;
    text-align: justify;
    line-height: 1.5;
    margin-left: 20%;
    margin-top: 5%;
  }

  /* Responsive Design */
  @media screen and (max-width: 1024px) {
    .donation-banner {
      height: 250px;
    }
    .donation-banner-image{
      height: auto;
    }
    .donate-message-section{
      margin-top: 16%;
    }
  
    .donation-form-section {
      flex-direction: column; /* Stack elements vertically */
      align-items: center; /* Center content */
    }
  
    .donation-scanner {
      max-width: 80%; /* Reduce image size on smaller screens */
      margin-bottom: 20px; /* Add space below image */
    }
  
    .donation-details {
      margin-top: 1%;
    }
    .donation-details p {
      font-size: 20px; /* Reduce text size for smaller screens */
      margin-left: 10px;
      margin-right: 10px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .donation-banner {
      height: 200px;
    }
    .donate-message-section{
      margin-top: 14%;
    }
    .donation-banner-image{
      /* width: 70%; */
      height: auto;
    }
  
    .donation-buttons {
      flex-direction: column;
      gap: 10px;
    }
  
    .donation-button {
      /* width: 50%; */
      padding: 12px 20px;
    }
    .donation-form-section {
      flex-direction: column; /* Stack elements vertically */
      align-items: center; /* Center content */
      padding: 20px; /* Reduce padding */
    }
  
    .donation-scanner {
      max-width: 90%; /* Make image wider on smaller screens */
    }
    .donation-scanner h5, .donation-details h5{
      font-size: 26px;
    }
    .donation-details p{
      font-size: 24px; /* Further reduce text size */
      margin-top: 0;
    }
  }
  
  @media screen and (max-width: 480px) {
    .donation-banner {
      height: 150px;
      /* width: 400px; */
    }
    .donate-message-section{
      margin-top: 1%;
    }
    .donation-banner-image{
      /* width: 70%; */
      height: auto;
    }
  
    .donate-message-section h2{
      font-size: 1.19rem;
    }
    .donate-message-section p {
      text-align: justify;
      font-size: 14px;
    }
    .donation-buttons {
      flex-direction: column;
      gap: 8px;
    }
  
    .donation-button {
      width: 50%;
      font-size: 14px;
      /* padding: 10px 10px; */
    }
    .donation-scanner {
      max-width: 80%; /* Make image full width */
    }
    .donation-scanner h5, .donation-details h5{
      font-size: 20px;
      margin-left: 0px;
    }
    
    .donation-details p {
      font-size: 16px;
      margin-left: 0;
    }
  }
  