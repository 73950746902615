*{
    margin: 0%;
    font-family: 'BarlowRegular', Arial, sans-serif;
    line-height: 1.428571429;
}

/* Header Section Styling*/
.header-section {
  background-color: #d5eef4;
  padding: 10px 50px;
}

.logo {
  max-height: 120px;
  /* border-radius: 40%; */
  padding: 10px;
}

.header-text {
  text-align: center;
}

.foundation-name {
  color: rgb(8, 114, 156);
  font-size: 1rem;
  font-weight: bold;
}

.school-name {
  color: #ff1f1f;
  font-size: 1.59rem;
  font-weight: 500;
}

.school-address {
  font-size: 1rem;
  margin-top: 5px;
}
.reg-address {
  font-size: 0.7rem;
  margin: 1px;
  margin-top: -7px;
}

/* Responsive styles */
@media (max-width: 1024px) {
  .header-section {
    padding: 10px 30px;
  }

  .logo {
    max-height: 110px;
    padding-left: 15px;
  }

  .foundation-name {
    font-size: 0.9rem;
  }

  .school-name {
    font-size: 1.5rem;
  }

  .school-address {
    font-size: 0.9rem;
  }
}

@media (max-width: 768px) {
  .header-section {
    padding: 10px 20px;
    flex-direction: column; /* Stack elements vertically on smaller screens */
    align-items: center;
  }

  .logo {
    padding-left: 0;
    margin-bottom: 10px;
    max-height: 90px; /* Shrinks logo for smaller screens */
  }

  .header-text {
    text-align: center;
  }

  .foundation-name {
    font-size: 0.85rem;
  }

  .school-name {
    font-size: 1.3rem;
  }

  .school-address {
    font-size: 0.85rem;
  }
}
@media (max-width: 540px) {
  .logo {
    max-height: 80px;
    margin-left: 40px;
  }
}
@media (max-width: 480px) {
  .header-section {
    padding: 10px;
  }

  .logo {
    max-height: 80px;
    margin-left: 40px;
  }

  .foundation-name {
    font-size: 0.8rem;
  }

  .school-name {
    font-size: 1.1rem;
  }

  .school-address {
    font-size: 0.8rem;
  }
}


/* Navbar Section Styling*/
.custom-navbar {
  /* padding: 20px; */
  background-color: #f1f2f2  ;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.1);
  /* border-bottom: 1px solid #e5e5e5; */
}
.navbar-light .navbar-nav .nav-link {
  color: #000000;
}
.navbar-light .navbar-nav .nav-link:hover {
  color: #000000;
}
.custom-navbar .navbar-nav {
  width: 100%; /* Ensure nav items span full width */
  justify-content: center; /* Center the links */
}
.custom-navbar .nav-link {
  font-weight: 500;
  margin: 0 6px; /* Add space between links using margin */
}

.custom-navbar .navbar-toggler {
  border: none; /* Remove default border */
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: left;
  
}

.custom-navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

/* Navbar links styling */
.custom-navbar .nav-link {
  padding: 0;
  font-size: 16px; /* Adjust font size */
}

/* Dropdown menu styling */
.custom-navbar .dropdown-menu {
  border-radius: 5px;
}

.cta-btnn {
  background-color: #ff0000; /* Primary red color */
  /* color: #ffffff; */
  margin-top: -5px;
  padding: 5px 3px;
  /* font-size: 0.9em; */
  /* font-weight: bold; */
  border-radius:20px;
  border: none;
  cursor: pointer;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.cta-btnn:hover {
  background-color: #4664a1; /* Dark blue on hover */
  color: #ffffff;
  transform: scale(1.05); /* Slightly enlarges button on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adds shadow for depth */
}

/* Icon styling if you include an icon in the button */
.cta-btnn .icon {
  margin-right: 8px;
  font-size: 1.2em; 
}

/* Responsive adjustments */
@media (max-width: 1199.98px) { /* Bootstrap xl breakpoint */
  .custom-navbar .navbar-toggler {
    margin-left: auto; /* Center toggler in mobile view */
  }

  .custom-navbar .navbar-collapse {
    flex-direction: column; /* Stack items vertically */
    align-items: flex-start; /* Align items to the left */
  }

  .custom-navbar .navbar-nav {
    width: 100%; /* Ensure nav items span full width */
  }

  .custom-navbar .nav-link {
    /* padding: 10px 10px; */
    text-align: left;
  }

  .custom-navbar .nav-item {
    margin-bottom: 0; /* Remove margin between nav items */
  }

  .cta-btnn {
    width: 90px;
    /* margin-top: -2%; */
    /* padding: 5px; */
    /* font-size: 0.7em; */
  }
}

@media (max-width: 991.98px) { /* Bootstrap lg breakpoint */
  .custom-navbar .navbar-toggler {
    margin-left: auto;
  }

  .custom-navbar .navbar-nav .navbar-collapse {
    flex-direction: column; /* Stack nav items vertically */
    align-items: flex-start;
  }

  .custom-navbar .navbar-nav {
    width: 100%; /* Ensure nav items span full width */
    text-align: left;
  }

  .custom-navbar .nav-link {
    padding: 10px 20px;
    text-align: left;
  }

  .custom-navbar .nav-item {
    margin-bottom: 0px; 
  }
  .custom-navbar .cta-btnn{
    /* justify-items: center; */
    margin-left: 20px;
    justify-content: center;
  }
  .cta-btnn {
    width: 100px;
    height: 45px;
    font-size: 18px;
  }
}

@media (max-width: 768px) { /* Bootstrap md breakpoint */
  .custom-navbar .navbar-toggler {
    margin-left: auto;
    padding: 0px 10px;
  }

  .custom-navbar .navbar-collapse {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .custom-navbar .navbar-nav {
    width: 100%;
    padding-right: 500px;
    padding-left: 0;
    text-align: left;
  }

  .custom-navbar .nav-link {
    width: 100%;
    text-align: left;
  }

  .custom-navbar .nav-item {
    width: 100%;
    margin-bottom: 5px;
  }

  .custom-navbar .navbar-collapse.collapse.show {
    align-items: flex-start;
  }
  .custom-navbar .cta-btnn{
    /* justify-items: center; */
    margin-left: 20px;
    justify-content: right;
  }
  .cta-btnn {
    width: 100px;
    height: 50px;
    font-size: 18px;
  }
}

@media (max-width: 540px) { /* Bootstrap md breakpoint */
  .custom-navbar .navbar-toggler {
    margin-left: auto;
    padding: 0px 20px;
  }

  .custom-navbar .navbar-collapse {
    display: flex;
    flex-direction: column;
  }

  .custom-navbar .navbar-nav {
    width: 100%;
    padding-right: 200px;
    padding-left: 0;
    text-align: left;
  }

  .custom-navbar .nav-link {
    justify-content: center;
    width: 100%;
    text-align: left;
  }

  .custom-navbar .nav-item {
    width: 100%;
    margin-bottom: 5px;
  }

  .custom-navbar .navbar-collapse.collapse.show {
    align-items: flex-start;
  }

  .cta-btnn {
    width: 100px;
    height: 50px;
    font-size: 18px;
  }
}
@media (max-width: 350px) {
  .custom-navbar .navbar-nav {
    width: 100%;
    padding-right: 150px;
    padding-left: 0;
    text-align: left;
  }
}
/* Contact Section Styling */
.contact-section {
  background-color: #e0f7fa; /* Light blue background */
  padding: 40px;
  text-align: center;
}

.contact-title {
  font-size: 2.5rem;
  font-weight: 650;
  color: #295bb1;
}

.contact-subtitle {
  font-size: 1.25rem;
  color: #37474f; /* Gray color for subtitle */
  margin-bottom: 20px;
}

.contact-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.contact-card {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.contact-card h4 {
  font-size: 1.5rem;
  color: #042c6f;
  margin-bottom: 10px;
}

.contact-card p {
  font-size: 1rem;
  color: #37474f;
}

.icon {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #0288d1; /* Icon color */
}



/* Hero Section Styling */
.hero-section {
  position: relative;
  width: 100%;
  height: 90vh; /* Height for larger screens */
  overflow: hidden; /* Ensures that slides don't overflow the container */
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
}

.hero-slide {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  /* background-size: contain; */
  background-position: center;
  opacity: 0;
  /* background: no-repeat; */
  transition: opacity 0.5s ease-in-out; /* Smooth transition effect */
}

.hero-slide.active {
  opacity: 1;
}

.hero-content {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  color: #f3f3f8;
  text-align: center;
  z-index: 10;
  max-width: 100%;
}

.cta-btn {
  padding: 8px 12px;
  background-color: #1692e4;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 14px;
  border-radius: 5px;
  transition: background-color 0.3s; /* Smooth hover transition */
}

.cta-btn:hover {
  background-color: #4b4a4a;
}

/* Navigation Buttons */
/* .carousel-nav {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
}
.carousel-nav button {
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: #fff;
  font-size: 2rem;
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 50%;
  transition: background-color 0.3s;
}
.carousel-nav button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
.carousel-nav button:focus {
  outline: none;
} */

/* Text Content Styles */
.hero-section h1 {
  margin-top: 200px;
  font-size: 22px;
  line-height: 1;
}

.hero-section p {
  font-size: 15px;
  margin: 1rem 0;
  color: #fff;
}

@media (max-width: 1024px) {
  .hero-section {
    height: 80vh; /* Adjust height for smaller screens */
  }

  .hero-content {
    width: 100%;
    bottom: 5%; /* Adjust content position */
  }

  .hero-section h1 {
    font-size: 22px; /* Reduce heading size */
  }

  .hero-section p {
    font-size: 15px; /* Adjust paragraph size */
  }

  .cta-btn {
    font-size: 12px; /* Reduce button font size */
    padding: 6px 10px;
  }
}
@media (max-width: 912px) {
  .hero-section {
    height: 40vh;
  }
}
/* Small screens (portrait tablets, large phones) */
@media (max-width: 768px) {
  .hero-section {
    height: 40vh;
  }

  .hero-content {
    width: 90%;
    bottom: 5%; /* Adjust content position */
  }

  .hero-section h1 {
    font-size: 22px; /* Further reduce heading size */
    margin-top: 100px; /* Adjust margin for smaller screens */
  }

  .hero-section p {
    font-size: 15px;
  }

  .cta-btn {
    font-size: 12px;
    padding: 5px 8px;
  }
}

/* Extra small screens (small phones) */
@media (max-width: 480px) {
  .hero-section {
    height: 30vh;
  }

  .hero-content {
    width: 90%;
    bottom: 5%; /* Adjust content position */
  }

  .hero-section h1 {
    font-size: 18px;
    margin-top: 80px;
  }

  .hero-section p {
    font-size: 12px;
  }

  .cta-btn {
    font-size: 10px;
    padding: 4px 6px;
  }
}


/* About Us Section Styling */
.about-us {
  padding: 40px 80px;
  background-color: #eceef1;
}

.about-us-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: justify;
  max-width: 1200px;
  margin: 0 auto;
}

.text-content {
  flex: 1;
  padding-right: 2rem;
}

.image-content {
  flex: 0.7;
  flex-direction: column;
  display: flex;
  margin-top: -15px;
  gap: 10px;
}

.image-content img {
  max-width: 400px;
  max-height: 235px;
  border-radius: 10px;
}

.about-us h2 {
  font-size: 2rem;
  font-weight: 650;
  color: #295bb1;
  margin-bottom: 1rem;
}

.about-us p {
  font-size: 1.2rem;
  color: #0a0a0a;
  margin-bottom: 1rem;
}

@media (max-width: 1024px) {
  .about-us {
    padding: 30px 50px;
  }

  .about-us-content {
    flex-direction: column; /* Stack content vertically */
    text-align: justify;
  }
  .image-content {
    padding-top: 10px;
    order: 1; /* Keep images above the text */
    flex-direction: row;
    justify-content: space-between; /* Spread images evenly */
  }

  .image-content img {
    max-width: 50%; /* Two images side by side */
  }
  .text-content {
    order: 2;
    padding-right: 0;
    margin-top: 20px;
  }

  .about-us h2 {
    font-size: 1.8rem; /* Slightly smaller heading */
  }

  .about-us p {
    font-size: 1rem; /* Adjust font size for smaller screens */
  }
}

/* Small screens (Portrait tablets, large phones) */
@media (max-width: 768px) {
  .about-us {
    padding: 20px 20px;
  }

  .about-us h2 {
    font-size: 1.6rem; /* Further reduce heading size */
  }

  .about-us p {
    font-size: 0.9rem; /* Adjust paragraph font size */
  }

  .image-content img {
    max-width: 300px; /* Limit image size for smaller screens */
  }
}
@media (max-width: 540px) {
  .about-us {
    padding: 30px;
  }
  .image-content {
    flex-direction: column;
    justify-content: space-between; /* Spread images evenly */
  }

  .text-content {
    order: 2;
    padding-right: 0;
    margin-top: 20px;
  }
}
/* Extra small screens (Small phones) */
@media (max-width: 480px) {
  .about-us {
    padding: 15px 20px;
  }

  .about-us h2 {
    padding: 10px 0;
    font-size: 1.7rem; /* Smaller heading for tiny screens */
  }

  .about-us p {
    font-size: 1rem; /* Adjust font size for readability */
  }

  .image-content img {
    /* padding: 10px; */
    max-width: 100%; /* Further limit image size */
  }
}


/* 4. Legacy Section Style*/
.background-section {
  background-color: #f5f5f5;
  padding: 90px 0;
  position: relative;
}

.legacy-section {
  background-color: #f5f5f5;
  padding: 40px 0;
  margin: -60px auto;
  border-radius: 10px;
  width: 80%;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.legacy-section h2 {
  text-align: center;
  font-size: 2rem;
  font-weight: 650;
  color: #295bb1;
  margin-bottom: 30px;
}

.legacy-stats {
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto;
  flex-wrap: wrap; /* Allow items to wrap */
}

.legacy-item {
  text-align: center;
  flex: 1;
  padding: 10px;
}

.legacy-icon {
  width: 60px;
  height: 60px;
  margin-bottom: 15px;
}

.legacy-item h3 {
  font-size: 1.8rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.legacy-item p {
  font-size: 1.2rem;
  color: #666;
}

/* Enhanced Responsive Design */

/* Medium Screens (Tablets and Small Laptops) */
@media (max-width: 1024px) {
  .legacy-section {
    width: 90%;
  }
  
  .legacy-stats {
    flex-direction: row;
    justify-content: space-evenly;
  }
  
  .legacy-item {
    width: 30%;
  }
}

/* Mobile View (Screens Smaller than 768px) */
@media (max-width: 768px) {
  .legacy-section {
    width: 95%;
  }

  .legacy-stats {
    flex-direction: row;
    justify-content: space-between;
  }
  
  .legacy-item {
    flex: 1;
    margin: 0 5px;
    padding: 10px;
  }

  .legacy-icon {
    width: 50px;
    height: 50px;
  }

  .legacy-item h3 {
    font-size: 1.5rem;
  }

  .legacy-item p {
    font-size: 1rem;
  }
}

/* Mobile Small Screens (Screens Smaller than 480px) */
@media (max-width: 480px) {
  .legacy-section {
    padding: 20px 0;
  }
  .legacy-section h2{
    font-size: 1.5rem;
  }
  .legacy-stats {
    flex-direction: row;
    justify-content: space-between;
  }

  .legacy-item {
    flex: 1;
    margin: 0 5px;
    padding: 8px;
  }

  .legacy-icon {
    width: 45px;
    height: 45px;
  }

  .legacy-item h3 {
    font-size: 1.2rem;
  }

  .legacy-item p {
    font-size: 0.9rem;
  }
}


/* Vision & Mission section Styling */
.vision-mission-section {
  background-color: #f5f5f5;
  padding: 40px;
  text-align: center;
}

.vision-mission-container {
  display: grid;
  gap: 40px;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

/* Card styling */
.vision-card, .mission-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  min-height: 300px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-bottom: 20px; /* Space between cards */
  text-align: center; /* Center text content */
}

/* Hover effect */
.vision-card:hover, .mission-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Image styling */
.card-image {
  width: 50%;
  height: auto;
  margin-bottom: 20px;
}

.card-image img {
  width: 30%;
  height: auto;
  border-radius: 10px; /* Optional: add border-radius to image */
}

/* Heading styling */
.card-content h2 {
  font-size: 2rem; /* Increased font size */
  color: #295bb1; 
  margin-bottom: 10px;
}

.card-content p {
  font-size: 18px; /* Increased font size for better readability */
  color: #555;
  line-height: 1.5;
  text-align: justify;
  margin-bottom: 10px;
}

/* Responsiveness for smaller screens */
@media (max-width: 768px) {
  .vision-mission-container {
    grid-template-columns: 1fr; /* Make cards stack on smaller screens */
  }

  .card-image {
    width: 30%; /* Adjust image size for smaller screens */
  }

  .card-content h2 {
    font-size: 1.5rem; /* Adjust heading font size */
  }

  .card-content p {
    font-size: 16px; /* Adjust paragraph font size */
  }
}

@media (max-width: 480px) {
  .card-image {
    width: 40%; /* Further adjust image size for very small screens */
  }

  .card-content h2 {
    font-size: 1.25rem; /* Adjust heading font size */
  }

  .card-content p {
    font-size: 14px; /* Adjust paragraph font size */
  }
}




/* 6. President Message Section Style */
.president-message-section {
  padding: 40px 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #f4f5f7;
}

.president-message-container {
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap; 
}

.president-image {
  flex: 1;
  text-align: center;
  margin-right: 20px;
  margin-bottom: 549px;
}

.president-image img {
  width: 100%;
  max-width: 400px;
  border-radius: 10px;
  object-fit: cover;
}

.president-message-content {
  flex: 2;
}

.president-message-content h2 {
  font-size: 2rem;
  font-weight: 650;
  color: #295bb1;
  margin-bottom: 20px;
}

.president-message-content p {
  font-size: 18px;
  color: #666;
  line-height: 1.5;
  margin-top: 10px;
}

.president-message-content p:last-of-type {
  font-weight: bold;
  margin-top: 10px;
}


/* Responsive Styles */
@media (max-width: 768px) {
  .president-message-container {
    flex-direction: column;
    text-align: center;
  }

  .president-image {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .president-message-content h2 {
    font-size: 28px;
  }

  .president-message-content p {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .president-message-content h2 {
    font-size: 24px;
  }

  .president-message-content p {
    font-size: 14px;
  }
}


/* . Principal Message Section Style */
.principal-message-section {
  padding: 40px 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #f4f5f7;
}

.principal-message-container {
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap; 
}

.principal-image {
  flex: 1;
  text-align: center;
  margin-right: 20px;
  margin-bottom: 333px;
}

.principal-image img {
  width: 100%;
  max-width: 400px;
  border-radius: 10px;
  object-fit: cover;
}

.principal-message-content {
  flex: 2;
}

.principal-message-content h2 {
  font-size: 2rem;
  font-weight: 650;
  color: #295bb1;
  margin-bottom: 20px;
}

.principal-message-content p {
  font-size: 18px;
  color: #666;
  line-height: 1.5;
  margin-top: 10px;
}

.principal-message-content p:last-of-type {
  font-weight: bold;
  margin-top: 10px;
}


/* Responsive Styles */
@media (max-width: 768px) {
  .principal-message-container {
    flex-direction: column;
    text-align: center;
  }

  .principal-image {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .principal-message-content h2 {
    font-size: 28px;
  }

  .principal-message-content p {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .principal-message-content h2 {
    font-size: 24px;
  }

  .principal-message-content p {
    font-size: 14px;
  }
}


/* . Secretary Message Section Style */
.secretary-message-section {
  padding: 40px 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #f4f5f7;
}

.secretary-message-container {
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap; 
}

.secretary-image {
  flex: 1;
  text-align: center;
  margin-right: 20px;
  margin-bottom: 441px;
}

.secretary-image img {
  width: 100%;
  max-width: 400px;
  border-radius: 10px;
  object-fit: cover;
}

.secretary-message-content {
  flex: 2;
}

.secretary-message-content h2 {
  font-size: 2rem;
  font-weight: 650;
  color: #295bb1;
  margin-bottom: 20px;
}

.secretary-message-content p {
  font-size: 18px;
  color: #666;
  line-height: 1.5;
  margin-top: 10px;
}

.secretary-message-content p:last-of-type {
  font-weight: bold;
  margin-top: 10px;
}


/* Responsive Styles */
@media (max-width: 768px) {
  .secretary-message-container {
    flex-direction: column;
    text-align: center;
  }

  .secretary-image {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .secretary-message-content h2 {
    font-size: 28px;
  }

  .secretary-message-content p {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .secretary-message-content h2 {
    font-size: 24px;
  }

  .secretary-message-content p {
    font-size: 14px;
  }
}


/* CSRActivities.css */

.csr-activities {
  padding: 50px 20px;
  background-color: #f7f7f7;
}
.csr-activities h2{
  font-size: 2rem;
  font-weight: 650;
  color: #295bb1;
}

.csr-top-image {
  background-size: cover;
  background-position: center;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
}

.csr-top-image h1 {
  font-size: 3rem;
  font-weight: bold;
}

.corporate-section {
  margin: 40px 0;
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.corporate-section h2 {
  font-size: 2rem;
  font-weight: 650;
  color: #295bb1;
  margin-bottom: 20px;
}
.corporate-section h3{
  font-size: 2rem;
  font-weight: 650;
  color: #295bb1;
}

.corporate-section p {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #555;
  margin-bottom: 15px;
}

.corporate-section ul {
  list-style-type: disc;
  padding-left: 20px;
}

.corporate-section li {
  margin-bottom: 10px;
  font-size: 1.1rem;
}

.activity-slider {
  display: flex;
  overflow-x: auto;
  gap: 20px;
  padding: 20px;
}

.activity-card {
  min-width: 300px;
  max-width: 300px;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.activity-card:hover {
  transform: translateY(-10px);
}

.activity-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  animation: slideIn 1.5s ease;
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.activity-info {
  padding: 15px;
}

.activity-info h3 {
  font-size: 1.5rem;
  color: #08090a;
  margin: 10px 0;
}

@media (max-width: 768px) {
  .activity-slider {
    flex-wrap: nowrap;
  }
}

@media (max-width: 576px) {
  .activity-card {
    min-width: 250px;
  }

  .csr-activities h2 {
    font-size: 2rem;
  }

  .activity-info h3 {
    font-size: 1.2rem;
  }
}



/* 7. Courses Section Styles */
.courses {
  background-image: url('./assets/images/jxmufapp.png');
  background-size: cover;
  background-position: center;
  padding: 2rem 1rem;
  margin-bottom: 5px;
  background-color: #0d0c0c;
  text-align: center;
}

.courses h2 {
  font-size: 2rem;
  font-weight: 650;
  color: #295bb1;
  margin-bottom: 1rem;
}

.admission-button {
  background-color: #fc1937;
  color: #fff;
  border: none;
  font-weight: bold;
  padding: 1rem 1rem;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}
/* Hover effect for button */
.admission-button:hover {
  background-color: #ff4c4c;
  transform: scale(1.05);
}

.course-list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.course-card {
  background-color: #f8f5f5;
  padding: 2rem;
  margin: 1rem;
  border-radius: 10px;
  min-width: 290px;
  max-width: 300px;
  max-height: 320px;
  box-shadow: 0 0 10px rgba(18, 25, 17, 0.1);
  display: flex;
  flex-direction: column; /* Ensure content is stacked vertically */
  justify-content: space-between; /* Space out content vertically */
  transition: transform 0.1s ease, background-color 0.1s ease, box-shadow 0.1s ease;
}

/* Hover effect */
.course-card:hover {
  transform: scale(1.05); /* Slight zoom-in */
  background-color: #e0e0e0; /* Change background color */
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2); /* Deepen shadow */
}

.course-card h3 {
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

.course-card ul {
  font-size: 16px;
  list-style-type: disc;
  padding-left: 20px;
  text-align: left;
  flex-grow: 1; /* Allow the list to take up remaining space */
}

.course-card li {
  margin-bottom: 10px;
}

@media (max-width: 480px) {
  .courses h2 {
    font-size: 1.5rem;
  }
  .course-card ul {
    font-size: 15px;
  }
}

/* 8. Awards and Accomplishments Section */
.awardsaccomplishments-section {
  background-color: #f5f5f5;
  padding: 40px 20px;
  text-align: center;
}

.awardsaccomplishments-title {
  font-size: 2rem;
  font-weight: 650;
  color: #295bb1;
  margin-bottom: 20px;
}

.awardsaccomplishments-subtitle {
  font-size: 1rem;
  color: #666;
  margin-bottom: 40px;
}

/* Flex container for cards */
.awardsaccomplishments-cards {
  display: flex;
  overflow-x: auto; /* Scroll horizontally if needed */
  white-space: nowrap; 
  gap: 20px; /* Space between cards */
  margin-bottom: 20px;
  padding: 10px 0; /* Vertical padding to ensure space around cards */
}

/* Card styling */
.awardaccomplishments-card {
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between; /* Ensures the content stays centered and space is distributed */
  align-items: center;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 250px;
  height: 300px; /* Set a fixed height for uniformity */
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-right: 20px;
  margin-bottom: 10px;
}

/* Remove the last card margin */
.awardsaccomplishments-cards .awardaccomplishments-card:last-child {
  margin-right: 0;
}

/* Hover effect */
.awardaccomplishments-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Image styling */
.awardaccomplishments-image {
  width: 100px;
  height: auto;
  margin-bottom: 20px;
  display: block;
}

/* Heading styling */
.awardaccomplishments-card h3 {
  font-size: 1rem;
  color: #8e44ad; /* Purple heading */
}

.awardaccomplishments-card h4 {
  font-size: 0.9rem;
  color: #2c3e50; /* Darker heading */
  margin-bottom: 10px;
}

.awardaccomplishments-card p {
  font-size: 0.875rem;
  color: #555;
  margin-bottom: auto; /* Pushes the paragraph down to the bottom */
}

/* Responsiveness for smaller screens */
@media (max-width: 768px) {
  .awardsaccomplishments-title {
    font-size: 2rem;
  }

  .awardsaccomplishments-subtitle {
    font-size: 1rem;
  }

  .awardsaccomplishments-cards {
    flex-direction: column; /* Stack cards vertically */
    align-items: center; /* Center cards */
    gap: 15px; /* Adjust gap for smaller screens */
  }

  .awardaccomplishments-card {
    width: 90%; /* Full width on small screens */
    height: auto; /* Auto height */
  }
}

@media (max-width: 480px) {
  .awardsaccomplishments-title {
    font-size: 1.7rem;
  }

  .awardsaccomplishments-subtitle {
    font-size: 0.875rem;
  }

  .awardsaccomplishments-cards {
    gap: 10px; /* Smaller gap for extra small screens */
  }

  .awardaccomplishments-card {
    width: 95%; /* Full width with slight margin */
    height: auto; /* Auto height */
    padding: 15px;
  }

  .awardaccomplishments-image {
    width: 80px;
  }

  .awardaccomplishments-card h4 {
    font-size: 1.1rem;
  }
}


/* 9. Announcemnts and News Updates Setion Style*/
.container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  gap: 30px;
}

.section {
  width: 45%;
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.section-title {
  text-align: center;
  margin: 0 0 20px 0;
  font-size: 1.5rem;
  /* font-weight: 650; */
  color: #295bb1;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
}

.item:last-child {
  border-bottom: none; /* Remove bottom border from the last item */
}

.item-title {
  font-size: 1.1rem;
  color: #555;
}

.item-link {
  background-color: #479ffd;
  color: white;
  padding: 5px 15px;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1rem;
}

.item-link:hover {
  background-color: #0056b3;
  color: white;
  text-decoration: none;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
    gap: 20px;
  }

  .section {
    width: 100%;
  }

  .section-title {
    font-size: 1.3rem;
  }

  .item-title {
    font-size: 1rem;
  }

  .item-link {
    font-size: 0.8rem;
    padding: 4px 12px;
  }
}

@media (max-width: 480px) {
  .section-title {
    font-size: 1.2rem;
  }

  .item-title {
    font-size: 1rem;
  }

  .item-link {
    font-size: 0.8rem;
    padding: 3px 10px;
  }
}


/* Container for the whole gallery section */
.gallery-section {
  text-align: center;
  background-color: #fff;
  padding: 40px 0;
}

.gallery-title {
  font-size: 2rem;
  font-weight: 650;
  color: #295bb1;
  margin-bottom: 20px;
}

/* Grid layout for the gallery items */
.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 20px;
  padding: 0 20px;
}

/* Each item in the gallery */
.gallery-item {
  overflow: hidden;
  position: relative;
  transition: transform 0.3s ease;
}

.gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gallery-item:hover {
  transform: scale(1.05);
}

/* Style for the View Gallery button */
.gallery-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #f97316; /* Adjust button color */
  color: white;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.gallery-button:hover {
  background-color: #ff6600; /* Adjust hover effect */
}
@media (max-width: 480px) {
  .gallery-title {
    font-size: 1.7rem;
  }
}

/*///////////////Gallery Details ///////////////////*/
.gallery-detail-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  /* background-color: #f0f0f0; */
}

.gallery-detail-images {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Responsive grid */
  gap: 10px;
  width: 100%;
  max-width: 1000px;
}

.gallery-detail-images img {
  width: 100%;
  height: auto;
  object-fit: cover; /* Maintain aspect ratio */
  border-radius: 8px;
  cursor: pointer;
}

.carousel-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  padding: 20px;
}

.carousel-container {
  margin-left: 10%;
  margin-bottom: 5%;
  width: 100%;
  max-width: 1000px;
  height: auto; /* Height adjusts based on aspect ratio */
  max-height: 90vh; /* Limit height to 80% of viewport */
  background: transparent;
  /* position:absolute; */
}

.carousel-item img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-width: 100%;
  max-height: 100%; /* Keep image within container height */
}

.close-carousel {
  position: absolute;
  top: 50px;
  right: 15%;
  background: transparent;
  /* border: none; */
  font-size: 24px; /* Increased size for better visibility */
  color: white; /* Use white for better contrast on dark background */
  cursor: pointer;
  z-index: 1001;
}


/* 11. Advantages Section Style */
.advantage-section {
  background-color: #e9eff0; /* Light blue background */
  padding: 30px;
  text-align: center;
}

.advantage-title {
  font-size: 2rem;
  font-weight: 650;
  color: #295bb1;
}

.advantage-subtitle {
  font-size: 1.2rem;
  color: #37474f; /* Gray color for subtitle */
  margin-bottom: 20px;
}

.advantage-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.advantage-card {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.advantage-card h3 {
  font-size: 1.5rem;
  color: #042c6f;
  margin-bottom: 10px;
}

.advantage-card ul {
  list-style: none;
  padding: 0;
}

.advantage-card ul li {
  margin: 8px 0;
  font-size: 1rem;
  color: #37474f;
}

.icon {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #0288d1;
}

@media (max-width: 480px) {
  .advantage-title {
    font-size: 1.7rem;
  }
  .advantage-subtitle {
    font-size: 1rem;
  }
  .advantage-card h3 {
    font-size: 1.4rem;
  }
}

/* 12. Fee Structure Section Style*/
.fee-structure-section {
  padding: 40px 20px;
  background-color: #f9f9f9;
  text-align: center;
}

.fee-structure-section h2 {
  font-size: 2rem;
  font-weight: 650;
  color: #295bb1;
  margin-bottom: 30px;
}

.fee-table {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  border-collapse: collapse;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.fee-table thead {
  background-color: #969293;
  color: #fff;
}

.fee-table th, .fee-table td {
  padding: 15px 20px;
  text-align: left;
  font-size: 18px;
  border-bottom: 1px solid #ddd;
}

.fee-table th {
  font-weight: bold;
}

.fee-table tr:last-child td {
  border-bottom: none;
}

.fee-table tbody tr:hover {
  background-color: #f0f0f0;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .fee-table th, .fee-table td {
    font-size: 16px;
    padding: 12px 15px;
  }
}

@media (max-width: 480px) {
  .fee-table {
    width: 100%;
    font-size: 14px;
  }

  .fee-table th, .fee-table td {
    padding: 10px;
  }
}

/* . Testimonials Section Styles */
.testimonials {
  padding: 2rem 2rem;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 5px;
  background-color: #ffffff;
  text-align: center;
}

.testimonials h2 {
  font-size: 2rem;
  font-weight: 650;
  color: #295bb1;
  margin-bottom: 1rem;
}

.testimonials-list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.testimonial {
  max-width: 300px;
  padding: 1rem;
  margin: 1rem;
  border-radius: 10px;
  background-color: #d6d68c69;
  box-shadow: 0 5px 10px rgba(35, 4, 4, 0.1);
  text-align: center;
}

.testimonial-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 0.5rem;
}

.testimonial p {
  font-size: 1rem;
  color: #090909;
  margin-bottom: 0.5rem;
}

.testimonial h4 {
  font-size: 1.1rem;
  font-weight: bold;
  color: #484851;
}

@media (max-width: 480px) {
  .testimonials h2 {
    font-size: 1.66rem;
  }
}

/* 13. Footer Section Styles */
.footer {
  background-color: #f1f2f2 ;
  color: #000000;
  padding: 1rem 2rem;
  text-align: center;
}

.footer-columns {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
}

.footer-BDHlogo-socials {
  flex: 1 1 20%; /* Adjust width for this column */
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center content horizontally */
  margin: 0.5rem;
}

.footer-BDHlogo img {
  width: 140px; 
  height: 145px;
  margin-bottom: -1rem; 
}

.footer-socials {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.social-icon {
  /* color: #000000; */
  font-size: 1.5rem;
  margin: 0 10px;
  text-decoration: none;
}

.social-icon:hover {
  color: #625e5e;
}

.footer-address {
  flex: 1 1 30%; /* Column width */
  margin: 0.5rem;
  text-align: left;
}

.footer-address p{
  font-size: 16px;
}

.footer-designed {
  width: 100%;
  background-color: #4e4e4e; /* Black background */
  color: #fff; /* White text */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
}

.footer-designed p {
  color: #fff;
  margin: 0;
  font-size: 15px;
  padding: 0 1rem;
}

.footer-designed p:first-child {
  flex: 1; /* Align to the left */
  text-align: left;
}

.footer-designed p:last-child {
  flex: 1; /* Align to the right */
  text-align: right;

}
.footer-designed p a:last-child, .footer-designed p a:first-child {
  text-decoration: none;
  color: white;
}
/* .footer-designed {
  text-align: center;
  margin-top: 1rem;
} */

.footer-logo {
  width: 55px;
  margin-left: 0.5rem;
  vertical-align: middle;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .footer-columns {
    flex-direction: column; /* Stack columns vertically */
    align-items: center;
  }

  .footer-socials,
  .footer-address {
    flex: 1 1 100%; /* Full width for each section */
    text-align: center;
  }

  .footer-logo {
    width: 50px; /* Smaller logo for mobile */
  }
  .footer-designed {
    flex-direction: column; /* Stack items vertically */
    text-align: center;
  }

  .footer-designed p {
    padding: 0.5rem 0;
    text-align: center; /* Center-align text on smaller screens */
  }
}

@media (max-width: 480px) {
  .footer {
    padding: 1rem;
  }

  .footer-logo {
    width: 80px; /* Smaller logo for very small screens */
  }
}
