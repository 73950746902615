.chairman-message-container {
  padding: 2rem 1rem;
  background-color: #f8f9fa; /* Light background */
  display: flex;
  justify-content: center;
  align-items: center;
}

.chairman-message-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: 1050px;
  gap: 2rem;
}

.chairman-image img {
  width: 450px;
  height: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.chairman-text {
  flex: 1;
  color: #333;
  text-align: justify;
}

.chairman-text .quote {
  font-size: 1.1rem;
  line-height: 1.428;
  position: relative;
}

.author {
  font-size: 1rem;
  font-weight: 600;
  color: #0056b3;
  margin-bottom: -5px;
}

/* RESPONSIVENESS */
@media (max-width: 768px) {
  .chairman-message-content {
    flex-direction: column;
    text-align: center;
  }

  .chairman-image img {
    width: 80%; /* Shrink image on smaller screens */
  }

  .chairman-text .quote {
    font-size: 1rem;
  }

  .author {
    font-size: 0.9rem;
  }
}
